import trunc from 'lodash.truncate'
import styled from '@emotion/styled'
import { Location } from '@reach/router'
import React, { useMemo } from "react"
import { Link, graphql } from "gatsby"
import { Disqus } from 'gatsby-plugin-disqus'

import { getResolvedVersionForLanguage } from '../utils/node'
import Layout from "../components/layout"
import PageLastUpdatedDate from "../components/pageLastUpdatedDate"
import Seo from "../components/seo"
import Markdown from "../components/markdown"

const StyledPageLastUpdatedDate = styled(PageLastUpdatedDate)`
  font-size: 1.2rem;
  margin: 1rem 0 0;
`

const StyledMarkdown = styled(Markdown)`
  font-size: 1.4rem;
  background-color: ${({ theme }) => theme.contentSection.bgColor};
  color: ${({ theme }) => theme.contentSection.textColor};
  border-radius: 5px;
  margin-top: 2.5rem;

  ${({ theme }) => theme.media.when({ minW: 'desktop' })} {
    font-size: 1.4rem;
  }
`

const Heading = styled.h1`
  margin: 1rem 0 0;
`

const Comments = styled.div`
  margin-top: 4.5rem;
`

const BottomNav = styled.div`
  margin-top: 1.5rem;
  padding: 1rem 0;
  border-top: 1px dashed ${({ theme }) => theme.pageBottomNav.borderColor};
  border-bottom: 1px dashed ${({ theme }) => theme.pageBottomNav.borderColor};
  font-size: 0.8rem;
  line-height: 1rem;
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    li {
      max-width: 40%;
      a {
        margin: 0 0.5em;
      }
    }
  }
`

const PageBottomNavItemLink = ({ currentLanguage, item }) => {
  const { title } = useMemo(() => (
    getResolvedVersionForLanguage(item.versions, currentLanguage, item.lang)
  ), [ item, currentLanguage ])

  return (
    <Link to={item.path}>{title}</Link>
  )
}

const PageBottomNav = ({ currentLanguage, newer, older }) => {
  if (!newer && !older) {
    return null
  }

  return (
    <BottomNav>
      <ul>
        <li>
          {newer ? (
            <>
              ⇦<PageBottomNavItemLink currentLanguage={currentLanguage} item={newer} />
            </>

          ) : null}
        </li>
        <li>
          {older ? (
            <>
              <PageBottomNavItemLink currentLanguage={currentLanguage} item={older} />⇨
            </>
          ) : null}
        </li>
      </ul>
    </BottomNav>
  )
}

const Page = ({ siteUrl, current, ...nav }) => {
  const { type, lang: fallbackLang, versions } = current

  const fields = useMemo(() => (
    getResolvedVersionForLanguage(versions, fallbackLang, fallbackLang)
  ), [ versions, fallbackLang ])

  const summary = useMemo(() => {
    const src = fields.summary || fields.markdown

    return src ? trunc(src, { length: 100 }) : null
  }, [ fields ])

  return (
    <Layout>
      <Seo title={fields.title} description={summary} ogi={fields.ogi} />
      <Heading>{fields.title}</Heading>
      <StyledPageLastUpdatedDate date={fields.date} />
      <StyledMarkdown markdown={fields.markdown} />
      {type === 'blog' ? <PageBottomNav {...nav} /> : null}
      {type === 'blog' ? (
        <Comments>
          <Location>
            {({ location }) => (
              <Disqus
                config={{
                  url: `${siteUrl}${location.pathname}`,
                  identifier: location.pathname,
                  title: fields.title,
                }}
              />
            )}
          </Location>
        </Comments>
      ) : null}
    </Layout>
  )
}

export default function Template({ data }) {
  return (
    <Page
      siteUrl={data.site.siteMetadata.siteUrl}
      current={data.current}
      newer={data.newer}
      older={data.older}
    />
  )
}

export const pageQuery = graphql`
  fragment MarkdownPageFields on MarkdownPage {
    path
    type
    lang
    versions {
      lang
      date
      title
      summary
      markdown
      ogi
    }
  }

  query($id: String!, $newerPageId: String, $olderPageId: String) {
    current: markdownPage( id: {  eq: $id } ) {
      ...MarkdownPageFields
    }
    newer: markdownPage( id: { eq: $newerPageId } ) {
      ...MarkdownPageFields
    }
    older: markdownPage( id: { eq: $olderPageId } ) {
      ...MarkdownPageFields
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
